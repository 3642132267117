<template>
  <div class="user_detail">
    <q-list bordered separator>
        <q-item >
            <q-item-section>{{ $t('login.user_id') }}</q-item-section>
            <q-item-section>{{ user_id_readonly }}</q-item-section>
            <q-item-section><div>
                <q-btn outline :label="$t('login.change_password')" color="negative" v-on:click="$router.push({name: 'ChangePassword', params: {user_id: user_id_readonly}})" />
            </div></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('login.username') }}</q-item-section>
            <q-item-section>{{ username }}</q-item-section>
            <q-item-section></q-item-section>
        </q-item>
        <q-item >
            <q-item-section>{{ $t('login.remark') }}</q-item-section>
            <q-item-section>
            <div v-if="remark_readonly">
                {{ remark }}
            </div>
            <q-input v-else outlined v-model="remark" />
            </q-item-section>
            <q-item-section>
                <div>
                    <q-btn v-if="remark_readonly" outline :label="$t('app.edit')" color="secondary" v-on:click="switch_remark_readonly()" />
                    <q-btn v-else outline :label="$t('form.submit')" color="secondary" v-on:click="change_info()" />
                </div>
            </q-item-section>
        </q-item>
    </q-list>

  </div>
  <Alert v-if="alert_show" :messages="alert_messages" :key="alert_messages"></Alert>
</template>
<script>
import Alert from '@/components/Alert.vue';

export default {
    props: {
        user_id: String,
    },
    components: {Alert},
    data() {
        return {
            user_id_readonly: 'user_id',
            username: 'username',
            remark: 'remark',
            password: '',
            
            origin_remark: 'remark',
            remark_readonly: true,

            password_disable: true,

            alert_show: false,
            alert_messages: [],
        }
    },
    computed: {
    },
    async created () {
        this.retrieve_info();
    },
    methods: {
        alert(messages) {
            this.alert_messages = messages;
            this.alert_show = true;
        },
        reset (data) {
            this.username = data.username;
            this.remark = data.last_name;
            this.origin_remark = this.remark,
            this.user_id_readonly = data.id;

            this.remark_readonly = true;
            this.password_disable = true;
        },
        switch_remark_readonly () {
            if (!this.remark_readonly) {
                this.remark = this.origin_remark;
            }
            this.remark_readonly = !this.remark_readonly;
        },
        switch_password_disable () {
            if (!this.password_disable) {
                this.password = '';
            }
            this.password_disable = !this.password_disable;
        },
        async retrieve_info() {
            try {
                let response = await this.$fetch('/api/v1/user/'+this.user_id, {
                    method: 'get',
                });
                let data = await response.json();
                this.reset(data);
            } catch (e) {
                this.alert([this.$t('error.retry')]);
            }
        },
        async update_info(key, value) {
            let data = {};
            data[key] = value;
            try {
                let response = await this.$fetch('/api/v1/user/'+this.user_id, {
                    body: JSON.stringify(data),
                    method: 'PUT'
                });
                if (response.status == 200) {
                    let data = await response.json();
                    this.reset(data);
                } else {
                    throw "response.status: " + response.status;
                }
            } catch (e) {
                this.alert([this.$t('error.retry')]);
            }
        },
        async change_info() {
            if (!this.password_disable) {
                let result = this.$valid_password(this.password);
                if (result.length == 0) {
                    this.update_info('password', this.password);
                }
                else {
                    let messages = [];
                    for (let item of result) {
                        messages.push(item.message);
                    }
                    this.alert(messages);
                }
            }
            if (!this.remark_readonly) {
                this.update_info('last_name', this.remark);
            } 
        }
    }
}
</script>
<style scoped>
.item {
    padding: 10px
}
</style>