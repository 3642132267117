<template>
    <div class="row" style="padding-top: 20px; padding-bottom:10px">
        <q-card class="col-4">
            <q-card-section style="align-items: center; justify-content: center;display: flex;">
                <q-icon name="account_balance" style="font-size: 1.5em;" /> 
                <q-space /> 
                <div>{{ farms.length }} {{ $t('app.farm') }}</div>
            </q-card-section>
        </q-card>
    </div>

    <q-list bordered separator style="background-color: white">
        <q-item clickable v-ripple v-for="(f, ix) in farms" :key="f.id"  @click="$router.push({name: 'Farm', params: {farm_id: f.id, user_id: user_id}})" >
            <q-item-section>#{{ ix + 1 }} {{ f.name }}</q-item-section>
        </q-item>
        <q-item class="row justify-end">
            <q-btn v-if="!add_farm" outline icon="add" :label="$t('app.add_farm') +' ' + $t('app.farm') " type="submit" color="secondary" @click="add_farm = true" />
        </q-item>
    </q-list>

    <div style="text-align: right; margin-top: 10px">
 
    </div>

    <div v-if="add_farm" style="padding-top: 0px; padding: 15px">
        <div>
        <q-input filled v-model="newFarmName" :label="$t('app.new_farm_name')" :dense="dense" color="secondary" />
        </div>
        <div class="col row justify-center">
        <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.cancel')" type="submit" color="secondary" v-on:click="add_farm = false"/>
        <q-btn style="margin: 20px; width: 80px" outline :label="$t('app.ok')" type="submit" color="secondary" v-on:click="add_new_farm()" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        user_id: String,
    },
    data() {
        return {
            farms: [],

            add_farm: false,
            newFarmName: ''
        }
    },
    computed: {
    },
    async created () {
        this.retrieve_list();
    },
    methods: {
        async retrieve_list() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/farm', {
                method: 'get',
            });
            let data = await response.json();
            this.farms = data;
        },
        async add_new_farm() {
            let response = await this.$fetch('/api/v1/user/'+this.user_id +'/farm', {
                method: 'post',
                body: JSON.stringify({
                    'user_id': this.user_id,
                    'name': this.newFarmName,
                }),
            });
            if (response.status == 200) {
                this.newFarmName = '';
                this.retrieve_list();
                this.add_farm = false;
            }
        }
    }
}
</script>
<style scoped>
.item {
    padding: 10px
}
</style>