<template>
  <div class="home" style="padding: 15px">
    <UserDetail :user_id="user_id" />
    <FarmList :user_id="user_id" />
  </div>
</template>

<script>
import UserDetail from '@/components/UserDetail.vue'
import FarmList from '@/components/FarmList.vue'

export default {
    data () {
        return {
            user_id: this.$route.params.user_id,
        }
    },
    components: {UserDetail, FarmList},
}
</script>